<template>
  <div>
    <h1>黒い原稿用紙</h1>
    現在 黒い原稿用紙.com は、コンテンツ変更・拡充のため、一時的に閉鎖しております。<br>
    お問い合わせ：<a href="https://twitter.com/_KozyCorner_">@_KozyCorner_</a> <br><br>

    <a href="https://twitter.com/search?q=%22%E9%BB%92%E3%81%84%E5%8E%9F%E7%A8%BF%E7%94%A8%E7%B4%99%22%20OR%20%23%E9%BB%92%E3%81%84%E5%8E%9F%E7%A8%BF%E7%94%A8%E7%B4%99%20filter%3Aimages&src=typed_query">
      #黒い原稿用紙
    </a>

    <br><br>
    ©2018-2021 ︎黒い原稿用紙, KozyCorner
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: #aaaaaa;
  font-weight: 700;
}
</style>
